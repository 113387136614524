<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <h4>
                <router-link :to="{ name: 'signups' }" class="mr-2">
                    <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                </router-link>
                Sign-up Settings
            </h4>
            <ShareSignup
                class="my-3"
                :signup_id="signup.id"
            />
            <b-form-group
                label="Name"
            >
                <b-input v-model="signup.name" placeholder="Signup name" />
            </b-form-group> 
            <b-form-group
                label="Description"
            >
                <b-textarea v-model="signup.description" placeholder="Signup description"></b-textarea>
            </b-form-group>
            <b-form-group
                label="Visibility"
            >
                <b-checkbox
                    v-model="signup.pinned"
                    switch
                ></b-checkbox>
            </b-form-group>
            <b-form-group
                label="Allow Sign-up Changes"
            >
                <b-checkbox v-model="signup.allow_changes" switch></b-checkbox>
            </b-form-group>
            <b-form-group
                label="Lock Sign-up"
            >
                <b-checkbox v-model="signup.locked" switch></b-checkbox>
            </b-form-group>
            <b-form-group
                label="Add Others to Signup"
            >
                <b-checkbox v-model="signup.add_other" switch></b-checkbox>
            </b-form-group>
            <b-form-group
                label="Max Sign-ups"
            >
                <b-input v-model.number="signup.max_members"></b-input>
            </b-form-group>
            <b-form-group
                label="Expires"
            >
                <b-input type="datetime-local" v-model="signup.expires"></b-input>
            </b-form-group>
            <div class="text-center mt-4">
                <b-button
                    :disabled="saving"
                    variant="primary"
                    size="lg"
                    @click="saveSignup()"
                    pill
                >
                    <b-spinner v-if="saving" variant="light"></b-spinner>
                    <font-awesome-icon v-else icon="save"></font-awesome-icon>
                    Save Sign-up
                </b-button>
            </div>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import ShareSignup from './ShareSignup.vue';

export default {
    props: ["id"],
    components: { ShareSignup },
    data: function () {
        return {
            loading     : false,
            saving      : false,
            signup      : {},
            settingGroups: [{ icon: 'cog', text: 'General', value: 'S' }, { icon: 'users', text: 'People', value: 'P' }, { icon: 'file-invoice', text: 'Forms', value: 'F' }]
        };
    },
    mounted() {
        this.loadSignup();
    },
    methods: {
        loadSignup() {
            this.loading = true;
            this.$api.get(`/api/sign-ups/admin/${this.id}/fetch`).then(({ data: { signup } }) => {
                this.signup  = {signup_type: '', ...signup};
                this.loading = false;
            });
        },
        saveSignup() {
            this.saving = true;
            this.$api.post(`/api/sign-ups/admin/${this.id}/save`, this.signup).then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.saving = false;
            });
        }
    }
}
</script>