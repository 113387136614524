<template>
    <ShareLink
        title="Get signup link"
        :link="formUrl"
        :filename="filename"
    />
</template>
<script>
import { mapState } from "vuex"
import ShareLink from '@/components/share/ShareLink.vue'

export default {
    components: { ShareLink },
    props: ["signup_id"],
    computed: {
        formUrl() {
            return `${this.group.group_base_url || window.location.host}/signups/${this.signup_id}`;
        },
        filename() {
            return `${this.group.group_name}-signup-${this.signup_id}-qr-code.png`;
        },
        ...mapState(["group"])
    }
}
</script>